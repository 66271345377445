import * as React from "react";
import { Link } from "gatsby";
import { FancyBackground } from "../components/fancy-background";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <FancyBackground />
      <title>Not found</title>
      <h1 style={headingStyles}>404 Not found</h1>
      <p style={paragraphStyles}>
        Sorry :(, we couldn't find what you were looking for.
        <br />
      </p>
    </main>
  );
};

export default NotFoundPage;
